import _ from 'lodash';
import { Close, Preview } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { Children, useCallback, useMemo, useState } from 'react';
import Util from '../../../lib/util';

export const SfmcObjectTypes = [
  {
    name: 'Assets - Code snippet blocks',
    value: 'codesnippetblock',
    hasPath: true,
  },
  {
    name: 'Assets - HTML Content blocks',
    value: 'htmlblock',
    hasPath: true,
  },
  {
    name: 'Assets - Html emails',
    value: 'htmlemail',
    hasPath: true,
  },
  {
    name: 'Automations',
    value: 'automation',
    hasPath: true,
  },
  {
    name: 'Data extensions',
    value: 'dataExtension',
    hasPath: true,
  },
  {
    name: 'Data extract activities',
    value: 'dataExtract',
    hasPath: false,
  },
  {
    name: 'File transfer activities',
    value: 'fileTransfer',
    hasPath: false,
  },
  {
    name: 'Import activities',
    value: 'importFile',
    hasPath: false,
  },
  {
    name: 'Query activities',
    value: 'query',
    hasPath: true,
  },
  {
    name: 'Script activities',
    value: 'script',
    hasPath: true,
  },
];
Util.deepFreeze(SfmcObjectTypes);

const SfmcObjectTypesLowerCaseMap = _.keyBy(SfmcObjectTypes, (type) =>
  type.value.toLowerCase()
);

export default function PackageSummary({
  objectReferences,
  missingObjects,
  unsupportedDependencies,
  dialogTitle,
  buttonTitle,
  showButton,
}) {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = useCallback(() => {
    setOpenDialog(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setOpenDialog(false);
  }, []);

  const unsupportedTypes = Object.keys(unsupportedDependencies || {});
  const types = useMemo(
    () =>
      SfmcObjectTypes.map((type) => type.value)
        .concat(
          unsupportedTypes.filter(
            (type) => !SfmcObjectTypesLowerCaseMap[type.toLowerCase()]
          )
        )
        .filter(
          (type) =>
            objectReferences?.[type]?.length ||
            missingObjects?.[type]?.length ||
            unsupportedDependencies?.[type]?.length
        ),
    [
      missingObjects,
      objectReferences,
      unsupportedDependencies,
      unsupportedTypes,
    ]
  );

  buttonTitle =
    buttonTitle && typeof buttonTitle === 'string'
      ? buttonTitle
      : 'Review the objects list';

  const objects = useMemo(() => {
    const combined = {};

    for (const type of types) {
      const missing =
        missingObjects?.[type]?.map((o) => {
          return { ...o, missing: true };
        }) || [];
      const unsupported =
        unsupportedDependencies?.[type]?.map((o) => {
          return { ...o, unsupported: true };
        }) || [];
      const inPackage = objectReferences[type] || [];
      combined[type] = _.sortBy(
        _.concat(inPackage, missing, unsupported),
        (o) => (o.name || o.suggestedName || o.key || o.id || '').toLowerCase()
      );
    }

    return combined;
  }, [objectReferences, missingObjects, unsupportedDependencies, types]);

  const hasMissingOrUnsupported =
    Object.values(missingObjects || {}).some((o) => o.length) ||
    Object.values(unsupportedDependencies || {}).some((o) => o.length);

  return (
    <div>
      {showButton ? (
        <Button
          type="button"
          color="info"
          variant="contained"
          onClick={handleOpenDialog}
          disabled={types.length === 0}
          startIcon={<Preview />}
        >
          {buttonTitle}
        </Button>
      ) : (
        <></>
      )}
      <Dialog
        className="builder-suite-package selected-objects-dialog"
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle fullwidth="true">
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            {dialogTitle || 'Selected objects'}
            <IconButton
              aria-label="close"
              onClick={handleCloseDialog}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          {hasMissingOrUnsupported ? (
            <Alert
              severity="warning"
              sx={{ mb: 1, textAlign: 'left', maxWidth: '100vw' }}
            >
              The package was generated despite some objects being missing or
              unsupported. Please note that this package is incomplete.
            </Alert>
          ) : (
            <></>
          )}
          {Children.toArray(
            types.map((type, index) => {
              const byPath = _.groupBy(
                objects[type],
                (item) => item.wsfmc?.folder?.path || ''
              );
              const paths = _.sortBy(Object.keys(byPath), (path) =>
                path.split('/').map((part) => part.toLowerCase())
              );

              const typeObj = SfmcObjectTypesLowerCaseMap[type?.toLowerCase()];
              return (
                <div>
                  <div
                    style={{
                      marginTop: index > 0 ? '15px' : '0',
                    }}
                  >
                    <strong>{typeObj?.name || type}</strong>
                  </div>
                  {Children.toArray(
                    paths.map((path) => (
                      <div
                        style={{
                          marginTop: '5px',
                        }}
                      >
                        {typeObj?.hasPath ? (
                          <em>{path || '[path unknown or undefined]'}</em>
                        ) : (
                          <></>
                        )}
                        <ul
                          style={{
                            marginTop: '5px',
                          }}
                        >
                          {Children.toArray(
                            byPath[path].map((obj) => (
                              <li
                                style={{
                                  padding: '3px 0 3px 0',
                                }}
                              >
                                <span>
                                  {obj.name ||
                                    obj.suggestedName ||
                                    obj.key ||
                                    obj.id}
                                </span>
                                {obj.missing ? (
                                  <em style={{ color: 'rgb(255, 86, 86)' }}>
                                    {' '}
                                    (missing)
                                  </em>
                                ) : obj.unsupported ? (
                                  <em style={{ color: 'rgb(255, 86, 86)' }}>
                                    {' '}
                                    (unsupported)
                                  </em>
                                ) : obj.dependency ? (
                                  <em style={{ color: '#0060af' }}>
                                    {' '}
                                    (dependency)
                                  </em>
                                ) : (
                                  <></>
                                )}
                              </li>
                            ))
                          )}
                        </ul>
                      </div>
                    ))
                  )}
                </div>
              );
            })
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
